"use strict";
import bookmark from "./bookmark.js";
import messagePopup from "./messagePopup.js";
import recipes from "./recipes.js";
var recipesEl = document.querySelector(".recipes");
var copyRight = document.querySelector(".copy-right");
var searchInput = document.querySelector(".search__field");
var searchBtn = document.querySelector(".search__btn");
var seeMoreBtn = document.querySelector(".see-more");
var logo = document.querySelector(".logo");
var currentYear = document.querySelector(".current-year");
// -------------------------------------------------
// APP CLASS
var FoodieApp = /** @class */ (function () {
    function FoodieApp() {
        var _a, _b;
        this.bookmarkRecipe = [];
        this.bookmark = "fa-regular";
        // Set home page
        window.name = "home";
        // Get Bookmarks from Local Storage
        bookmark.getBookmarkRecipes();
        // Bookmark Button
        (_a = document
            .querySelector(".nav__btn--bookmarks")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function (e) {
            e.preventDefault();
            if (recipes.recipeFromStorage.length !== 0) {
                bookmark.openBookmark(e);
            }
            else {
                messagePopup.successMessage("", "No bookmarks yet. Find a nice recipe and bookmark it :)", "failed");
            }
        });
        // Bookmark Trash
        (_b = document.querySelectorAll(".trash")) === null || _b === void 0 ? void 0 : _b.forEach(function (e) {
            return e.addEventListener("click", function (e) {
                recipes.removeRecipe(e);
            });
        });
        // SHOW SEARCH RESULT
        searchBtn.addEventListener("click", function (e) {
            e.preventDefault();
            recipes.seeMore = false;
            window.name = "recipes";
            recipesEl.removeAttribute("style");
            if ((searchInput === null || searchInput === void 0 ? void 0 : searchInput.value) !== "") {
                if (!seeMoreBtn.classList.contains("hidden"))
                    seeMoreBtn.classList.add("hidden");
                if (!copyRight.classList.contains("hidden"))
                    copyRight.classList.add("hidden");
                recipes.showResult();
                recipes.showRecipe();
            }
            else {
                messagePopup.errorHandler("Input field is empty! Try again");
            }
        });
        // See More Button
        seeMoreBtn === null || seeMoreBtn === void 0 ? void 0 : seeMoreBtn.addEventListener("click", function (e) {
            e.preventDefault();
            var oldElements = document.querySelectorAll(".bookmark-round");
            oldElements === null || oldElements === void 0 ? void 0 : oldElements.forEach(function (el) {
                return el.parentNode.replaceChild(el.cloneNode(true), el);
            });
            recipes.seeMore = true;
            seeMoreBtn.style.cursor = "not-allowed";
            seeMoreBtn.style.pointerEvents = "none";
            seeMoreBtn.style.backgroundImage =
                "linear-gradient(to right bottom, #ffba0857, #d0000057)";
            recipes.showResult();
            recipes.showRecipe();
        });
        // HIDE SEARCH RESULT
        logo.addEventListener("click", function (e) {
            e.preventDefault();
            window.name = "home";
            recipes.closeResult();
        });
        // Copy Right
        currentYear.innerHTML = new Date().getFullYear().toString();
    }
    return FoodieApp;
}());
// const foodieApp = new FoodieApp();
export default new FoodieApp();
