"use strict";
import messagePopup from "./messagePopup.js";
import recipes from "./recipes.js";
var recipesEl = document.querySelector(".recipes");
var copyRight = document.querySelector(".copy-right");
var seeMoreBtn = document.querySelector(".see-more");
var bookmarksList = document.querySelector(".bookmarks__list");
var Bookmark = /** @class */ (function () {
    function Bookmark() {
    }
    Bookmark.prototype.openBookmark = function (e) {
        var _this = this;
        if (!seeMoreBtn.classList.contains("hidden"))
            seeMoreBtn.classList.add("hidden");
        if (!copyRight.classList.contains("hidden"))
            copyRight.classList.add("hidden");
        recipes.showRecipes = recipes.recipeFromStorage;
        if (recipesEl.childElementCount !== 0) {
            document.querySelectorAll(".recipe").forEach(function (el) { return el.remove(); });
        }
        recipesEl.style.paddingBottom = "6rem";
        window.name = "bookmark";
        recipes.spinner(recipesEl);
        recipes.showResult();
        setTimeout(function () {
            var _a;
            recipes.renderResult();
            (_a = document.querySelectorAll(".bookmark-round")) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                return el.addEventListener("click", function (e) {
                    _this.addRecipe(e);
                });
            });
            if (copyRight.classList.contains("hidden"))
                copyRight.classList.remove("hidden");
        }, 1000);
    };
    // -------------------------------------------------
    // ADD RECIPE TO BOOKMARK
    Bookmark.prototype.addRecipe = function (e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        (_a = document.querySelector(".bookmarks__message")) === null || _a === void 0 ? void 0 : _a.classList.add("hidden");
        var recipe = e.target.closest(".recipe");
        var recipeName = (_c = (_b = recipe === null || recipe === void 0 ? void 0 : recipe.querySelector(".recipe__title")) === null || _b === void 0 ? void 0 : _b.querySelector("span")) === null || _c === void 0 ? void 0 : _c.innerHTML;
        var referenceNumber = recipe === null || recipe === void 0 ? void 0 : recipe.className.split(" ")[1];
        if (!((_d = recipe.querySelector(".fa-bookmark")) === null || _d === void 0 ? void 0 : _d.classList.contains("fa-solid"))) {
            if (e.target instanceof HTMLElement) {
                (_f = (_e = e.target
                    .closest(".recipe")) === null || _e === void 0 ? void 0 : _e.querySelector(".fa-bookmark")) === null || _f === void 0 ? void 0 : _f.classList.remove("fa-regular");
            }
            if (e.target instanceof HTMLElement) {
                (_h = (_g = e.target
                    .closest(".recipe")) === null || _g === void 0 ? void 0 : _g.querySelector(".fa-bookmark")) === null || _h === void 0 ? void 0 : _h.classList.add("fa-solid");
            }
            if (!((_j = document.querySelector(".bookmark-btn")) === null || _j === void 0 ? void 0 : _j.classList.contains("fa-solid")))
                (_k = document.querySelector(".bookmark-btn")) === null || _k === void 0 ? void 0 : _k.classList.add("fa-solid");
            var recipeFilter = recipes.recipes.filter(function (e) { return e.referenceNumber === referenceNumber; })[0];
            recipes.recipeFromStorage.unshift(recipeFilter);
            localStorage.setItem("recipes", JSON.stringify(recipes.recipeFromStorage));
            this.getBookmarkRecipes();
            (_l = document.querySelectorAll(".trash")) === null || _l === void 0 ? void 0 : _l.forEach(function (e) {
                return e.addEventListener("click", function (e) {
                    recipes.removeRecipe(e);
                });
            });
            messagePopup.successMessage("".concat(recipeName), "has been added to bookmark succesfully!", "successed");
        }
        else {
            (_o = (_m = e.target
                .closest(".recipe")) === null || _m === void 0 ? void 0 : _m.querySelector(".fa-bookmark")) === null || _o === void 0 ? void 0 : _o.classList.remove("fa-solid");
            (_q = (_p = e.target
                .closest(".recipe")) === null || _p === void 0 ? void 0 : _p.querySelector(".fa-bookmark")) === null || _q === void 0 ? void 0 : _q.classList.add("fa-regular");
            messagePopup.successMessage("".concat(recipeName), "has been removed from bookmark succesfully!", "successed");
            recipes.getIndex = recipes.recipeFromStorage.findIndex(function (i) { return i.referenceNumber === referenceNumber; });
            var recipeEl = recipes.recipeFromStorage[recipes.getIndex].referenceNumber;
            if (window.name === "recipes") {
                (_r = document.querySelector(".".concat(recipeEl))) === null || _r === void 0 ? void 0 : _r.remove();
            }
            if (window.name === "bookmark") {
                document
                    .querySelectorAll(".".concat(recipeEl))
                    .forEach(function (e) { return e.remove(); });
            }
            recipes.recipeFromStorage.splice(recipes.getIndex, 1);
            localStorage.recipes = JSON.stringify(recipes.recipeFromStorage);
            if (((_s = document.querySelector(".bookmarks__list")) === null || _s === void 0 ? void 0 : _s.childElementCount) === 0) {
                (_t = document.querySelector(".bookmarks__message")) === null || _t === void 0 ? void 0 : _t.classList.remove("hidden");
                (_u = document.querySelector(".bookmark-btn")) === null || _u === void 0 ? void 0 : _u.classList.remove("fa-solid");
                localStorage.removeItem("recipes");
                copyRight.removeAttribute("style");
                recipesEl.insertAdjacentHTML("afterbegin", "\n        <div class=\"bookmark-message\">\n          <p>\n            No bookmarks yet. Find a nice recipe and bookmark it :)\n          </p>\n        </div>");
            }
        }
    };
    // -------------------------------------------------
    // GET BOOKMARK RECIPE
    Bookmark.prototype.getBookmarkRecipes = function () {
        var _a, _b;
        document
            .querySelectorAll(".preview__bookmark")
            .forEach(function (el) { return el.remove(); });
        if (localStorage.recipes) {
            recipes.recipeFromStorage = JSON.parse(localStorage.recipes);
            (_a = document.querySelector(".bookmark-btn")) === null || _a === void 0 ? void 0 : _a.classList.add("fa-solid");
            (_b = document.querySelector(".bookmarks__message")) === null || _b === void 0 ? void 0 : _b.classList.add("hidden");
            for (var _i = 0, _c = recipes.recipeFromStorage; _i < _c.length; _i++) {
                var recipe = _c[_i];
                bookmarksList.insertAdjacentHTML("afterbegin", "\n     <li class=\"preview__bookmark ".concat(recipe.referenceNumber, "\">\n            <a class=\"preview__bookmark-link\">\n              <figure class=\"preview__bookmark-fig\">\n                <img src=\"").concat(recipe.image, "\" />\n              </figure>\n              <div class=\"preview__bookmark-data\">\n                <h4 class=\"preview__bookmark-title\">").concat(recipe.recipeName, "</h4>\n                <p class=\"preview__bookmark-publisher\">").concat(recipe.publisher, " - ").concat(recipe.cuisineType, "</p>\n              </div>\n              </a>\n              <button class=\"preview__bookmark-icon trash\">\n                <i class=\"fa-solid fa-trash-can\"></i>\n              </button>\n          </li>"));
            }
        }
    };
    return Bookmark;
}());
export default new Bookmark();
