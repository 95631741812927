"use strict";
var searchInput = document.querySelector(".search__field");
var messagePopupSuccess = document.querySelector(".message-popup-success");
var faCircleCheck = document.querySelector(".fa-circle-check");
var exclamationMark = document.querySelector(".exclamation-mark");
var incorrectDoneBtn = document.querySelector(".incorrect-done-btn");
var messageName = document.querySelector(".message-name");
var restMessage = document.querySelector(".rest-message");
var errorMessage = document.querySelector(".error-message");
var messageOverlay = document.querySelector(".message-overlay");
var messagePopupIncorrect = document.querySelector(".message-popup-incorrect");
var MessagePopup = /** @class */ (function () {
    function MessagePopup() {
    }
    // -------------------------------------------------
    //    MESSAGE HANDLER
    MessagePopup.prototype.successMessage = function (name, message, mark) {
        if (mark === "successed")
            faCircleCheck.classList.remove("hidden");
        if (mark === "failed")
            exclamationMark.classList.remove("hidden");
        messagePopupSuccess.classList.remove("hidden");
        messageOverlay.classList.remove("hidden");
        messageName.innerHTML = name;
        restMessage.innerHTML = message;
        setTimeout(function () {
            if (!faCircleCheck.classList.contains("hidden"))
                faCircleCheck.classList.add("hidden");
            if (!exclamationMark.classList.contains("hidden"))
                exclamationMark.classList.add("hidden");
            messagePopupSuccess.classList.add("hidden");
            messageOverlay.classList.add("hidden");
        }, 3000);
    };
    MessagePopup.prototype.errorHandler = function (message) {
        messagePopupIncorrect.classList.remove("hidden");
        messageOverlay.classList.remove("hidden");
        errorMessage.innerHTML = message;
        this.closeMessage();
    };
    MessagePopup.prototype.closeMessage = function () {
        incorrectDoneBtn.addEventListener("click", function () {
            messagePopupIncorrect.classList.add("hidden");
            messageOverlay.classList.add("hidden");
            searchInput === null || searchInput === void 0 ? void 0 : searchInput.focus();
        });
    };
    return MessagePopup;
}());
export default new MessagePopup();
